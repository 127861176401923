import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { db } from "../../lib/init-firebase";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import ShowImage from "../CommonComponents/ShowImage";

export default function PortfolioTablePrice(props) {
  const location = useLocation();
  const selectedChargers = location.state.selectedChargers;
  const chargerArray = Array.from(selectedChargers);

  let newChargerArray = chargerArray.map((str) => {
    let values = str.split(",");
    let firstValue = values[0];
    let secondValue = "";
    if (values.length > 2) {
      secondValue = values.slice(1, 2)[0];
    } else {
      secondValue = values.slice(-1)[0];
    }
    return firstValue + "," + secondValue;
  });
  //console.log(newChargerArray);

  const [dataSet, setDataSet] = useState(new Set());
  const [dataSetCompName, setDataSetCompName] = useState(new Set());
  const [dataSetPriceLink, setDataSetPriceLink] = useState(new Set());

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const queries = chargerArray.map((charger) =>
    doc(db, `ChargersList/${charger}/Portfolio analysis/${props.subcollection}`)
  );

  const queriesCompanyName = chargerArray.map((charger) =>
    doc(db, `ChargersList/${charger}/Portfolio analysis/Company Name`)
  );

  const queriesPriceLink = chargerArray.map((charger) =>
    doc(db, `ChargersList/${charger}/Portfolio analysis/Price Link`)
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const dataSet = new Set();

        for await (const query of queries) {
          const docSnap = await getDoc(query);
          if (docSnap.exists()) {
            dataSet.add(docSnap.data());
          } else {
            setError("No such document!");
          }
        }
        setDataSet([...dataSet]);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const dataSetCompName = new Set();

        for await (const queryCompName of queriesCompanyName) {
          const docSnap = await getDoc(queryCompName);
          if (docSnap.exists()) {
            dataSetCompName.add(docSnap.data());
          } else {
            setError("No such document!");
          }
        }
        setDataSetCompName([...dataSetCompName]);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const dataSetPriceLink = new Set();

        for await (const queryPriceLink of queriesPriceLink) {
          const docSnap = await getDoc(queryPriceLink);
          if (docSnap.exists()) {
            dataSetPriceLink.add(docSnap.data());
          } else {
            setError("No such document!");
          }
        }
        setDataSetPriceLink([...dataSetPriceLink]);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>error...</div>;
  }

  const chargerDataArray = [...dataSet].map((charger) => charger);
  const chargerCompanyArray = [...dataSetCompName].map(
    (charger) => charger.companyName
  );
  const chargerPriceLinkArray = [...dataSetPriceLink].map(
    (charger) => charger.priceLink
  );

  return (
    <TableContainer style={{ width: "90%", marginBottom: "50px" }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {chargerDataArray &&
              chargerDataArray.map((item, index) => (
                <TableCell key={index} align="center">
                  {
                    <ShowImage
                      chargerFolder={chargerCompanyArray[index]}
                      chargerName={newChargerArray[index]}
                    />
                  }
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {chargerDataArray &&
            props.keysCollection.map((filter) => (
              <TableRow hover key={filter}>
                <TableCell
                  style={{ fontWeight: "bold" }}
                  component="th"
                  scope="row"
                >
                  {filter}
                </TableCell>
                {chargerDataArray.map((row, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    component="th"
                    scope="row"
                    style={{
                      backgroundColor: isNaN(row[filter]) // Check if the value is NaN
                        ? "white" // If the value is NaN, set the background to white
                        : row[filter] ===
                          Math.min(
                            ...chargerDataArray.map((item) => item[filter])
                          )
                        ? "#d9efd9" // If the value is the minimum, set the background to light green
                        : row[filter] ===
                          Math.max(
                            ...chargerDataArray.map((item) => item[filter])
                          )
                        ? "#f7d5d5" // If the value is the maximum, set the background to light red
                        : "white", // If the value is a number but not the min or max, set the background to white
                    }}
                  >
                    {row[filter].toString().toLowerCase() === "false" ||
                    row[filter].toString().toLowerCase() === "no" ? (
                      <CloseIcon style={{ color: "darkred" }} />
                    ) : row[filter].toString().toLowerCase() === "true" ||
                      row[filter].toString().toLowerCase() === "yes" ? (
                      <DoneIcon style={{ color: "green" }} />
                    ) : typeof row[filter] === "number" ? (
                      (Math.round(row[filter] * 100) / 100).toFixed(2) + " €"
                    ) : (
                      row[filter]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {chargerDataArray &&
            props.keysCollectionLink.map((filter) => (
              <TableRow hover key={filter}>
                <TableCell
                  style={{ fontWeight: "bold" }}
                  component="th"
                  scope="row"
                >
                  {filter}
                </TableCell>
                {chargerPriceLinkArray.map((row, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <a href={row} target="_blank" rel="noopener noreferrer">
                      Link
                    </a>
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
