import React, { useState } from "react";
import axios from "axios";
const { Configuration, OpenAIApi } = require("openai");

export default function ChatGPT() {
  const [conversation, setConversation] = useState([]);
  const [userInput, setUserInput] = useState("");

  const sendMessage = async () => {
    if (userInput.trim() === "") {
      return;
    }

    addMessageToConversation(userInput, "user");
    setUserInput("");

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: userInput },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "PLACE-YOUR-API-KEY",
          },
        }
      );

      const botReply = response.data.choices[0].message.content;
      addMessageToConversation(botReply, "bot");
    } catch (error) {
      console.error(error);
    }
  };

  const addMessageToConversation = (message, role) => {
    setConversation((prevConversation) => [
      ...prevConversation,
      { role, message },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <div>
      <div>
        {conversation.map((msg, index) => (
          <div key={index} className={msg.role}>
            {msg.message.content}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
}
