import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabPanel, TabContext } from "@material-ui/lab";

import {
  collectionGeneral,
  collectionConnectivity,
  collectionEMS,
  collectionFeatures,
  collectionSafety,
  collectionUserIdentif,
  collectionProtocols,
  collectionDatasheet,
  collectionPrice,
  collectionPriceLink,
} from "../../TableData/keyValuesArrays";
import PortfolioTableComponent from "./PortfolioTableComponent";
import PortfolioTablePrice from "./PortfolioTablePrice";
import PortfolioTableDatasheet from "./PortfolioTableDatasheet";
import AttributeFilter from "../CommonComponents/AttributeFilter";
import { Grid } from "@material-ui/core";

export default function PorfolioNavBar() {
  const [value, setValue] = useState("1");

  useEffect(() => {
    setValue("1");
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Variables for each subsection
  const [selectedFiltersGeneral, setSelectedFiltersGeneral] = useState(
    new Set(collectionGeneral)
  );
  const [selectedFiltersComms, setSelectedFiltersComms] = useState(
    new Set(collectionConnectivity)
  );
  const [selectedFiltersEMS, setSelectedFiltersEMS] = useState(
    new Set(collectionEMS)
  );
  const [selectedFiltersFeatures, setSelectedFiltersFeatures] = useState(
    new Set(collectionFeatures)
  );
  const [selectedFiltersSafety, setSelectedFiltersSafety] = useState(
    new Set(collectionSafety)
  );
  const [selectedFiltersUserIdentif, setSelectedFiltersUserIdentif] = useState(
    new Set(collectionUserIdentif)
  );
  const [selectedFiltersProtocols, setSelectedFiltersProtocols] = useState(
    new Set(collectionProtocols)
  );
  const [selectedFiltersDatasheet, setSelectedFiltersDatasheet] = useState(
    new Set(collectionDatasheet)
  );
  const [selectedFiltersPrice, setSelectedFiltersPrice] = useState(
    new Set(collectionPrice)
  );
  const [selectedFiltersPriceLink, setSelectedFiltersPriceLink] = useState(
    new Set(collectionPriceLink)
  );

  //UseEffect hooks for each subsection
  useEffect(() => {
    setSelectedFiltersGeneral(new Set(collectionGeneral));
  }, []);

  useEffect(() => {
    setSelectedFiltersComms(new Set(collectionConnectivity));
  }, []);

  useEffect(() => {
    setSelectedFiltersEMS(new Set(collectionEMS));
  }, []);

  useEffect(() => {
    setSelectedFiltersFeatures(new Set(collectionFeatures));
  }, []);

  useEffect(() => {
    setSelectedFiltersSafety(new Set(collectionSafety));
  }, []);

  useEffect(() => {
    setSelectedFiltersUserIdentif(new Set(collectionUserIdentif));
  }, []);

  useEffect(() => {
    setSelectedFiltersProtocols(new Set(collectionProtocols));
  }, []);

  useEffect(() => {
    setSelectedFiltersDatasheet(new Set(collectionDatasheet));
  }, []);

  useEffect(() => {
    setSelectedFiltersPrice(new Set(collectionPrice));
  }, []);

  useEffect(() => {
    setSelectedFiltersPriceLink(new Set(collectionPriceLink));
  }, []);

  //Save filters for each subsection
  const saveFiltersGen = (selected) => {
    const selectedFiltersGeneral = new Set(selected);
    setSelectedFiltersGeneral(selectedFiltersGeneral);
  };

  const saveFiltersComms = (selected) => {
    const selectedFiltersComms = new Set(selected);
    setSelectedFiltersComms(selectedFiltersComms);
  };

  const saveFiltersEMS = (selected) => {
    const selectedFiltersEMS = new Set(selected);
    setSelectedFiltersEMS(selectedFiltersEMS);
  };

  const saveFiltersFeatures = (selected) => {
    const selectedFiltersFeatures = new Set(selected);
    setSelectedFiltersFeatures(selectedFiltersFeatures);
  };

  const saveFiltersSafety = (selected) => {
    const selectedFiltersSafety = new Set(selected);
    setSelectedFiltersSafety(selectedFiltersSafety);
  };

  const saveFiltersUserIdentif = (selected) => {
    const selectedFiltersUserIdentif = new Set(selected);
    setSelectedFiltersUserIdentif(selectedFiltersUserIdentif);
  };

  const saveFiltersProtocols = (selected) => {
    const selectedFiltersProtocols = new Set(selected);
    setSelectedFiltersProtocols(selectedFiltersProtocols);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#009b86",
              },
            }}
          >
            <Tab
              label="General"
              value="1"
              style={{
                textTransform: "none",
                fontSize: "16px",
              }}
            />
            <Tab
              label="Connectivity"
              value="2"
              style={{
                textTransform: "none",
                fontSize: "16px",
              }}
            />
            <Tab
              label="Energy Management Solutions"
              value="3"
              style={{ textTransform: "none", fontSize: "16px" }}
            />
            <Tab
              label="Features"
              value="4"
              style={{ textTransform: "none", fontSize: "16px" }}
            />
            <Tab
              label="Safety"
              value="5"
              style={{ textTransform: "none", fontSize: "16px" }}
            />
            <Tab
              label="User identification"
              value="6"
              style={{ textTransform: "none", fontSize: "16px" }}
            />
            <Tab
              label="Standards and protocols"
              value="7"
              style={{ textTransform: "none", fontSize: "16px" }}
            />
            <Tab
              label="Datasheets"
              value="8"
              style={{ textTransform: "none", fontSize: "16px" }}
            />
            <Tab
              label="Price"
              value="9"
              style={{ textTransform: "none", fontSize: "16px" }}
            />
          </TabList>
        </Box>
        {value === "1" && (
          <TabPanel value="1">
              <AttributeFilter
                key={1}
                save={saveFiltersGen}
                data={collectionGeneral}
                selected={selectedFiltersGeneral}
              />
            <PortfolioTableComponent
              subcollection={"General"}
              keysCollection={[...selectedFiltersGeneral]}
            />
          </TabPanel>
        )}
        {value === "2" && (
          <TabPanel value="2">
              <AttributeFilter
                key={2}
                save={saveFiltersComms}
                data={collectionConnectivity}
                selected={selectedFiltersComms}
              />
            <PortfolioTableComponent
              subcollection={"Connectivity"}
              keysCollection={[...selectedFiltersComms]}
            />
          </TabPanel>
        )}
        {value === "3" && (
          <TabPanel value="3">
              <AttributeFilter
                key={3}
                save={saveFiltersEMS}
                data={collectionEMS}
                selected={selectedFiltersEMS}
              />
            <PortfolioTableComponent
              subcollection={"Energy Management Solutions"}
              keysCollection={[...selectedFiltersEMS]}
            />
          </TabPanel>
        )}
        {value === "4" && (
          <TabPanel value="4">
              <AttributeFilter
                key={4}
                save={saveFiltersFeatures}
                data={collectionFeatures}
                selected={selectedFiltersFeatures}
              />
            <PortfolioTableComponent
              subcollection={"Features"}
              keysCollection={[...selectedFiltersFeatures]}
            />
          </TabPanel>
        )}
        {value === "5" && (
          <TabPanel value="5">
              <AttributeFilter
                key={5}
                save={saveFiltersSafety}
                data={collectionSafety}
                selected={selectedFiltersSafety}
              />
            <PortfolioTableComponent
              subcollection={"Safety"}
              keysCollection={[...selectedFiltersSafety]}
            />
          </TabPanel>
        )}
        {value === "6" && (
          <TabPanel value="6">
              <AttributeFilter
                key={6}
                save={saveFiltersUserIdentif}
                data={collectionUserIdentif}
                selected={selectedFiltersUserIdentif}
              />
            <PortfolioTableComponent
              subcollection={"User identification"}
              keysCollection={[...selectedFiltersUserIdentif]}
            />
          </TabPanel>
        )}
        {value === "7" && (
          <TabPanel value="7">
              <AttributeFilter
                key={7}
                save={saveFiltersProtocols}
                data={collectionProtocols}
                selected={selectedFiltersProtocols}
              />
            <PortfolioTableComponent
              subcollection={"Standards and protocols"}
              keysCollection={[...selectedFiltersProtocols]}
            />
          </TabPanel>
        )}
        {value === "8" && (
          <TabPanel value="8">
            <PortfolioTableDatasheet
              subcollection={"Datasheets"}
              keysCollection={[...selectedFiltersDatasheet]}
            />
          </TabPanel>
        )}
        {value === "9" && (
          <TabPanel value="9">
            <PortfolioTablePrice
              subcollection={"Price"}
              keysCollection={[...selectedFiltersPrice]}
              keysCollectionLink={[...selectedFiltersPriceLink]}
            />
          </TabPanel>
        )}
      </TabContext>
    </Box>
  );
}
