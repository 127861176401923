import "./App.css";

//import for login page
import Login from "./components/LoginComponents/Login";

//import for Route use
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//import of the rest of the pages
import LandingPage from "./MainPages/LandingPage";
import CompanyOverview from "./MainPages/CompanyOverview";
import PortfolioAnalysis from "./MainPages/PortfolioAnalysis";
import DeepDive from "./MainPages/DeepDive";
// import PriceComparison from "./MainPages/PriceComparison";
import AddCharger from "./firebaseComponents/AddCharger";

//import to check user authentication
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./lib/init-firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import RealtimeChargers from "./firebaseComponents/RealtimeChargers";
import RequestAccess from "./MainPages/RequestAccess";
import LoginButton from "./MainPages/LoginPage";
import LandingPageFilter from "./MainPages/LandingPageFilter";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginButton />} />
        <Route path="/landingpage" element={<LandingPage />} />
        {/* <Route path="/landingpagefilter" element={<LandingPageFilter />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/companyoverview" element={<CompanyOverview />} />
        <Route path="/portfolioanalysis" element={<PortfolioAnalysis />} />
        <Route path="/deepdive" element={<DeepDive />} />
        {/* <Route path="/pricecomparison" element={<PriceComparison />} /> */}
        <Route path="/addcharger" element={<RealtimeChargers />} />
        <Route path="/requestaccess" element={<RequestAccess />} />
      </Routes>
    </div>
  );
}

export default App;
