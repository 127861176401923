import React, { useState } from "react";
import { addDoc } from "firebase/firestore";
import { chargerCollectionRef } from "../lib/firestore.collections";
import { chargersCollectFilter } from "../lib/firestore.collections";

export default function AddCharger() {
  const [name, setName] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (name === "") {
      return;
    }
    setName("");
    addDoc(chargersCollectFilter, { name })
      .then((response) => {
        console.log(response.id);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  return (
    <div>
      <h4>Add charger</h4>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Charger name</label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button type="submit">Add charger</button>
      </form>
    </div>
  );
}
