import { Box, Container, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ShowLogo from "../components/CommonComponents/ShowLogo";
import CustomButton from "../components/LandingComponents/ButtonLanding";

export default function LoginButton() {
  let navigate = useNavigate();
  const routeChange = () => {
    navigate("/login");
  };
  return (
    <Container style={{ minHeight: "100vh", marginTop: "70px" }} maxWidth="xs">
      <Box style={{ marginBottom: "30px" }}>
        <ShowLogo imageName={"Logotype+Isotype-b.svg"} />
      </Box>
      <Box>
        <Typography variant="h4">Welcome to Wallboxversus</Typography>
        <Typography variant="h5">by the Innovation team</Typography>
      </Box>
      <CustomButton onClick={routeChange}>Login</CustomButton>
    </Container>
  );
}
