import ReactGoogleSlides from "react-google-slides";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { db } from "../../lib/init-firebase";
import { useState } from "react";

export default function GetSlides(props) {
  const [isSlidesLoading, setIsSlidesLoading] = useState(true);

  const query = doc(
    db,
    `ChargersList/${props.chargerName}/Deep dive/slidesPresentation`
  );
  const [collections] = useDocumentData(query);
  const link = collections?.linktoSlide;

  if (!link) {
    return (
      <p
        style={{
          margin: 40,
        }}
      >
        No presentation for <b>{props.chargerName}</b> charger available
      </p>
    );
  }

  setTimeout(() => {
    setIsSlidesLoading(false);
  }, 4000); //You could use any number you want, it just simulating loading of the component.

  if (isSlidesLoading) {
    return (
      <div>
        <h4>{props.chargerName} presentation</h4>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <h4>{props.chargerName} presentation</h4>
      <div>
        <ReactGoogleSlides
          width={1040}
          height={880}
          slidesLink={link}
          position={1}
          showControls
        />
      </div>
    </div>
  );
}