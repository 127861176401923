import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Box,
  Modal,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { CustomAttributeLabel } from "../OverviewComponents/ComparisonTableOverview";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  // },
  button: {
    // margin: theme.spacing(1),
    textTransform: "none",
    borderRadius: "4px", 
    border: "1px solid #009b86", 
    padding: "10px",
    color: "#009b86",
    backgroundColor: "white",
    fontSize: "14px",
    cursor: "pointer",
    marginTop: "20px",
    marginRight: "100px"
  },
  // formControl: {
  //   margin: theme.spacing(3),
  // },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: "8px",
  },
}));

export default function AttributeFilter(props) {
  const classes = useStyles();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [state, setState] = useState({
    open: false,
    selectedFilters: {},
  });

  const handleOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleSubmit = () => {
    let selected = Object.keys(state.selectedFilters).filter(
      (it) => state.selectedFilters[it] === true
    );
    props.save(selected);
    handleClose();
  };

  useEffect(() => {
    setState({
      ...state,
      selectedFilters: props.data.reduce(function (result, item, index, array) {
        result[item] = props.selected.has(item) ? true : false; //a, b, c
        return result;
      }, {}),
    });
  }, [props.selected]);

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        [name]: event.target.checked,
      },
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <button
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={handleOpen}
      >
        Filter features
      </button>
      <Modal open={state.open} onClose={handleClose}>
        <Grid
          style={{
            ...modalStyle,
            maxHeight: "90vh",
            overflowY: "scroll",
          }}
          className={classes.paper}
        >
          <FormControl component="fieldset" className={classes.formControl}>
            <Box my={1}>
              <FormLabel component="legend">
                Select features for comparison
              </FormLabel>
            </Box>
            <FormGroup>
              {props.data.map((filter) => (
                <FormControlLabel
                  key={filter}
                  control={
                    <Checkbox
                      color="primary"
                      key={filter}
                      checked={
                        state.selectedFilters && state.selectedFilters[filter]
                          ? state.selectedFilters[filter]
                          : false
                      }
                      onChange={handleChange(filter)}
                      value={filter}
                    />
                  }
                  label={<CustomAttributeLabel>{filter}</CustomAttributeLabel>}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Box my={1}>
            <Grid
              container
              direction="row"
              // justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Apply
                </button>
              </Grid>
              <Grid item>
                <button variant="contained" onClick={handleClose}>
                  Close
                </button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>
    </div>
  );
}
