import React, { useState, useEffect } from "react";
import { onSnapshot } from "firebase/firestore";
import Select from "react-select";
import AddCharger from "./AddCharger";
import EditCharger from "./EditCharger";
import ListChargers from "./ListChargers";
import UploadImages from "./UploadImage";

import { chargerCollectionRef } from "../lib/firestore.collections";
import { chargersCollectFilter } from "../lib/firestore.collections";
import DotApp from "./DotApp";

export default function RealtimeChargers() {
  const [chargers, setChargers] = useState([]);
  //   const [isClearable, setIsClearable] = useState(true);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
      padding: 10,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  const handleInputChange = (selectedOption) => {
    console.log("handleInputChange", selectedOption.label);
  };

  //   const handleClearable = (e) => {
  //     setIsClearable(true);
  //     e.stopPropagation();
  //   };

  useEffect(() => {
    const unsubscribe = onSnapshot(chargersCollectFilter, (snapshot) => {
      setChargers(
        snapshot.docs.map((doc) => ({ value: doc.id, label: doc.data().name }))
      );
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      <div>
        <h4>Chargers list</h4>
        {/* <Select
          styles={customStyles}
          onChange={handleInputChange}
          //   options={chargers}
        /> */}
        <div>
          <Select
            styles={customStyles}
            options={chargers}
            onChange={handleInputChange}
          />
        </div>
        <AddCharger />
        <EditCharger />
        <ListChargers />
        <UploadImages />
        <DotApp />
      </div>
    </div>
  );
}
