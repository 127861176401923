import React, { useState, useEffect, Fragment } from "react";
import ResponsiveAppBar from "../components/CommonComponents/ResponsiveAppBar";
import AttributeFilter from "../components/CommonComponents/AttributeFilter";
import ComparisonTableOverview from "../components/OverviewComponents/ComparisonTableOverview";
import { collectionOverview } from "../TableData/keyValuesArrays";
import { Container, Grid } from "@material-ui/core";

export default function CompanyOverview(props) {
  const [selectedFilters, setSelectedFilters] = useState(
    new Set(collectionOverview)
  );

  useEffect(() => {
    setSelectedFilters(new Set(collectionOverview));
  }, [collectionOverview]);

  const saveFilters = (selected) => {
    const selectedFilters = new Set(selected);
    setSelectedFilters(selectedFilters);
  };

  return (
    <Fragment>
      <ResponsiveAppBar />
      {/* <PorfolioNavBar /> */}
      {/* <Container>
        <Grid container justifyContent="flex-end"></Grid>
      </Container> */}
      <div>
        <AttributeFilter
          key={2}
          save={saveFilters}
          data={collectionOverview}
          selected={selectedFilters}
        />
      </div>
      <ComparisonTableOverview filters={[...selectedFilters]} key={3} />
    </Fragment>

    // <Fragment>
    //   <ResponsiveAppBar key={1} />
    //   <Container>
    //     <Grid container justifyContent="flex-end">
    //       <AttributeFilter
    //         key={2}
    //         save={saveFilters}
    //         data={collectionOverview}
    //         selected={selectedFilters}
    //       />
    //     </Grid>
    //   </Container>
    //   <ComparisonTableOverview filters={[...selectedFilters]} key={3} />
    // </Fragment>
  );
}
