import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useHistory } from "react-router-dom";
import { auth } from "../../lib/init-firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import {
  getRedirectResult,
  OAuthProvider,
  onAuthStateChanged,
  signInWithRedirect,
} from "firebase/auth";

async function handleRedirectResult(navigate) {
  try {
    const result = await getRedirectResult(auth);
    console.log("This is the redirect result:", result);
    const credential = OAuthProvider.credentialFromResult(result);
    // window.localStorage.setItem("on-auth-error", false);
    navigate("/landingpage");
    console.log(credential);
  } catch (error) {
    console.log("This is the catch error:", error.code);
    if (error.code === "auth/user-cancelled") {
      navigate("/requestaccess");
      console.log("Should navigate if entering this loop");
    }
  }
}

export default function Login() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const provider = new OAuthProvider("oidc.okta");

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      navigate("/landingpage");
    } else {
      signInWithRedirect(auth, provider);
      handleRedirectResult(navigate);
    }
  }, [user, navigate, loading, provider]);
}
