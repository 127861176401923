import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

const ActiveLink = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const selectedChargers = location.state.selectedChargers;

  return (
    <Link
      style={{
        textDecoration: "none",
        color: isActive ? "white" : "white",
        fontWeight: isActive ? "bold" : "normal",
        // textShadow: isActive ? "1px 1px 2px black" : "normal",
        textDecoration: isActive ? "underline" : "none",
        textUnderlineOffset: isActive ? "5px" : "0",
        textTransform: "none",
        fontSize: "17px",
      }}
      to={to}
      state={{
        selectedChargers: selectedChargers,
      }}
    >
      {children}
    </Link>
  );
};

export default function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const StyledAppBar = styled(AppBar)(() => ({
    backgroundColor: "#009b86",
    boxShadow:
      "rgba(54, 54, 54, 0.16) 0px 0px 1px 0px, rgba(54, 54, 54, 0.02) 0px 4px 4px 0px, rgba(54, 54, 54, 0.04) 0px 2px 8px 0px",
  }));

  const StyledToolbar = styled(Toolbar)(() => ({
    justifyContent: "center",
  }));

  return (
    <StyledAppBar position="static">
      <StyledToolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <MenuItem key={"landingpage"} onClick={handleCloseNavMenu}>
              <Typography textAlign="center">
                <ActiveLink
                  style={{ textDecoration: "none", color: "white" }}
                  to={`/landingpage`}
                >
                  {"Compare"}
                </ActiveLink>
              </Typography>
            </MenuItem>
            <MenuItem key={"portfolioanalysis"} onClick={handleCloseNavMenu}>
              <Typography textAlign="center">
                <ActiveLink
                  to={"/portfolioanalysis"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {"Portfolio analysis"}
                </ActiveLink>
              </Typography>
            </MenuItem>
            <MenuItem key={"companyoverview"} onClick={handleCloseNavMenu}>
              <Typography textAlign="center">
                <ActiveLink
                  style={{ textDecoration: "none", color: "white" }}
                  to={`/companyoverview`}
                >
                  {"Company overview"}
                </ActiveLink>
              </Typography>
            </MenuItem>
            <MenuItem key={"deepdive"} onClick={handleCloseNavMenu}>
              <Typography textAlign="center">
                <ActiveLink
                  style={{ textDecoration: "none", color: "white" }}
                  to={`/deepdive`}
                >
                  {"Deep dive"}
                </ActiveLink>
              </Typography>
            </MenuItem>
            {/* <MenuItem key={"pricecomparison"} onClick={handleCloseNavMenu}>
              <Typography textAlign="center">
                <ActiveLink
                  style={{ textDecoration: "none", color: "white" }}
                  to={`/pricecomparison`}
                >
                  {"Price comparison"}
                </ActiveLink>
              </Typography>
            </MenuItem> */}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Button
            key={"landingpage"}
            onClick={handleCloseNavMenu}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            <ActiveLink
              style={{ textDecoration: "none", color: "white" }}
              to={`/landingpage`}
            >
              {"Compare"}
            </ActiveLink>
          </Button>
          <Button
            key={"portfolioanalysis"}
            onClick={handleCloseNavMenu}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            <ActiveLink
              style={{ textDecoration: "none", color: "white" }}
              to={`/portfolioanalysis`}
            >
              {"Portfolio analysis"}
            </ActiveLink>
          </Button>
          <Button
            key={"companyoverview"}
            onClick={handleCloseNavMenu}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            <ActiveLink
              style={{ textDecoration: "none", color: "white" }}
              to={`/companyoverview`}
            >
              {"Company overview"}
            </ActiveLink>
          </Button>
          <Button
            key={"deepdive"}
            onClick={handleCloseNavMenu}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            <ActiveLink
              style={{ textDecoration: "none", color: "white" }}
              to={`/deepdive`}
            >
              {"Deep dive"}
            </ActiveLink>
          </Button>
          {/* <Button
            key={"pricecomparison"}
            onClick={handleCloseNavMenu}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            <ActiveLink
              style={{ textDecoration: "none", color: "white" }}
              to={`/pricecomparison`}
            >
              {"Price comparison"}
            </ActiveLink>
          </Button> */}
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
}
