import React from "react";
import ResponsiveAppBar from "../components/CommonComponents/ResponsiveAppBar";
import GetSlides from "../components/DeepdiveComponents/GetSlides";
import { useLocation } from "react-router-dom";

export default function DeepDive() {
  const location = useLocation();

  const selectedChargers = location.state.selectedChargers;
  const chargerArray = Array.from(selectedChargers);

  return (
    <div>
      <ResponsiveAppBar />
      <div style={{ marginBottom: "50px" }}>
        {chargerArray.map((charger, indx) => (
          <GetSlides key={indx} chargerName={charger} />
        ))}
      </div>
    </div>
  );
}
