import React, { useEffect, useState } from "react";
import { getDocs, doc, deleteDoc } from "firebase/firestore";
import { chargerCollectionRef } from "../lib/firestore.collections";
import { chargersCollectFilter } from "../lib/firestore.collections";
import { db } from "../lib/init-firebase";

export default function ListChargers() {
  const [chargers, setChargers] = useState([]);
  useEffect(() => {
    getChargers();
  }, []);

  useEffect(() => {
    console.log(chargers);
  }, [chargers]);

  function getChargers(params) {
    getDocs(chargersCollectFilter)
      .then((response) => {
        const chargs = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setChargers(chargs);
      })
      .catch((error) => console.log(error.message));
  }

  function deleteCharger(id) {
    const docRef = doc(db, "chargers", id);
    deleteDoc(docRef)
      .then(() => console.log("Document deleted"))
      .catch((error) => console.log(error.message));
  }

  return (
    <div>
      <h4>Delete chargers</h4>
      <button onClick={() => getChargers()}>Refresh chargers</button>
      <ul>
        {chargers.map((charger) => (
          <li key={charger.id}>
            {charger.id} : {charger.data.name}
            <button onClick={() => deleteCharger(charger.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}
