import React, { useState, useEffect } from "react";
import { storage } from "../../lib/init-firebase";
import { ref, getDownloadURL } from "firebase/storage";

export default function ShowLogo(props) {
  const [chargerImage, setChargerImage] = useState(null);

  useEffect(() => {
    const imageRef = ref(storage, `${props.imageName}`);

    let isMounted = true;

    getDownloadURL(imageRef)
      .then((url) => {
        if (isMounted) {
          setChargerImage([url]);
        }
      })
      .catch((error) => {
        console.log("Error retrieving image:", error);
      });

    return () => {
      isMounted = false;
    };
  }, [props.imageName]);

  return (
    <div>
      {chargerImage?.map((url) => (
        <img style={{ width: "180px" }} key={url} src={url} alt="charger" />
      ))}
    </div>
  );
}
