export const collectionOverview = [
  "Product name",
  "Company name",
  "Founded",
  "International presence",
  "Country",
  "Cumulative total chargers sold",
  "Factory location",
  "Last year chargers sold",
  "Last year revenue",
  "Number of employees",
  "Offices",
  "Public",
  "App downloads",
  "Software rating",
  "Other",
];

export const collectionGeneral = [
  "Char. Mode",
  "1P or 3P",
  "Power 1ph (kW)",
  "Power 3ph (kW)",
  "Socket or cable",
  "Dimensions (H x W x D (mm))",
  "Volume (dm3)",
  "Weight (kg)",
  "Home",
  "Business",
];

export const collectionConnectivity = [
  "3G/4G",
  "Bluetooth",
  "Wi-Fi",
  "Ethernet",
];

export const collectionEMS = [
  "Power Boost",
  "Power Sharing",
  "Dynamic Power Sharing",
  "ECO smart",
  "Number of chargers PS & DPS",
];

export const collectionFeatures = [
  "App",
  "Display",
  "LEDs",
  "MID (1P)",
  "MID (3P)",
];

export const collectionSafety = [
  "IK rating",
  "IP rating",
  "AC Leakage",
  "AC Leakage Type",
  "DC Leakage",
  "Earthing (UK) - 1P",
  "Earthing (UK) - 3P",
  "Operational temperature",
  "MCB / MCBO",
];

export const collectionUserIdentif = ["App", "RFID"];

export const collectionProtocols = [
  "OCPP 1.6",
  "OCPP 2.0",
  "ISO 15118",
  "Eichreicht",
  "ZE Ready",
];

export const collectionDatasheet = ["datasheet"];

export const collectionPrice = ["Price (excl. VAT)", "Price (incl. VAT)"];
export const collectionPriceLink = ["priceLink"];
