import React, { useState, useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { onSnapshot } from "firebase/firestore";
import { chargerCollectionRef } from "../lib/firestore.collections";
import { Link } from "react-router-dom";
import { styled as MUIStyled } from "@mui/material/styles";
import CustomButton from "../components/LandingComponents/ButtonLanding";
import ShowLogo from "../components/CommonComponents/ShowLogo";
import { db } from "../lib/init-firebase";
import { doc, getDoc } from "firebase/firestore";

export default function LandingPage(props) {
  const [chargers, setChargers] = useState([]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
      padding: 10,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  const BoxMargin = MUIStyled(Box)(() => ({
    marginTop: "60px",
    marginBottom: "15px",
  }));

  // const deleteKey = () =>
  //   doc(db, `DeleteKey/Charger1/PortfolioAnalysis/General`);

  var Charger1;
  var Charger2;
  const chargerArray = [Charger1, Charger2];

  const queries = chargerArray.map((charger) =>
    doc(db, `DeleteKey/${charger}/PortfolioAnalysis/General}`)
  );

  //console.log(queries);

  useEffect(() => {
    async function modifyData() {
      try {
        for await (const query of queries) {
          const docSnap = await getDoc(query);
          if (docSnap.exists()) {
            const newData = { ...docSnap.data() };
            newData["newKey"] = newData["KeytoModify"];
            delete newData["KeytoModify"];

            await docSnap.ref
              .update(newData)
              .then(() => {
                console.log("Document updated successfully");
              })
              .catch((error) => {
                setError(error);
              });
          } else {
            setError("No such document!");
          }
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    modifyData();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(chargerCollectionRef, (snapshot) => {
      setChargers(
        snapshot.docs.map((doc) => ({ value: doc.id, label: doc.id }))
      );
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const [selectedChargers, setselectedChargers] = useState(new Set());

  const handleAddToCompare = (selectedOption) => {
    const item = selectedOption.value;
    setselectedChargers((prevState) => new Set(prevState).add(item));
  };

  return (
    <Container style={{ minHeight: "100vh", marginTop: "70px" }} maxWidth="xs">
      {/* <Box>
        <Typography color={"red"} variant="h7">Development environment</Typography>
      </Box> */}
      <Box style={{ marginBottom: "40px" }}>
        <ShowLogo imageName={"Logotype+Isotype-b.svg"} />
      </Box>
      <Box>
        <Typography variant="h3">Wallboxversus</Typography>
        <Typography variant="h5">by the Innovation team</Typography>
      </Box>
      <BoxMargin>
        <Typography variant="h5">Compare up to 5 chargers</Typography>
      </BoxMargin>
      <Container maxWidth="xs">
        <Box>
          <Select
            styles={customStyles}
            options={chargers}
            onChange={handleAddToCompare}
          />
          <Select
            styles={customStyles}
            options={chargers}
            onChange={handleAddToCompare}
          />
          <Select
            styles={customStyles}
            options={chargers}
            onChange={handleAddToCompare}
          />
          <Select
            styles={customStyles}
            options={chargers}
            onChange={handleAddToCompare}
          />
          <Select
            styles={customStyles}
            options={chargers}
            onChange={handleAddToCompare}
          />
        </Box>
      </Container>
      <Link
        to="/portfolioanalysis"
        state={{
          selectedChargers: selectedChargers,
        }}
        style={{ textDecoration: "none" }}
      >
        <CustomButton>Search</CustomButton>
      </Link>
    </Container>
  );
}
