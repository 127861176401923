import { Container } from "@material-ui/core";
import emailjs from "emailjs-com";
import { useRef, useState } from "react";
import CustomButton from "../components/LandingComponents/ButtonLanding";
import "../components/LoginComponents/RequestAccess.css";

function Success() {
  return (
    <Container style={{ minHeight: "100vh", marginTop: "70px" }} maxWidth="xs">
      <h3>Thank you for your interest in Wallboxversus!</h3>
      <p>We will let you know as soon as we provide you access.</p>
    </Container>
  );
}

function Error() {
  return (
    <Container style={{ minHeight: "100vh", marginTop: "70px" }} maxWidth="xs">
      <h3>Something went wrong...</h3>
      <p>
        Please, fill the form again or contact the Innovation team through Slack
        or email.
      </p>
    </Container>
  );
}

export default function ErrorPage() {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_82lxwqf",
        "template_gt6p0us",
        form.current,
        "bHkAfDd9GAz2h8Cr1"
      )
      .then(
        (result) => {
          setIsSuccess(true);
          setIsError(false);
          form.current.reset();
        },
        (error) => {
          setIsSuccess(false);
          setIsError(true);
        }
      );
  };

  return (
    <Container style={{ minHeight: "100vh", marginTop: "70px" }} maxWidth="xs">
      {isSuccess && <Success />}
      {isError && <Error />}
      {!isSuccess && !isError && (
        <div>
          <h3>
            You need to request access to Wallboxversus. 
            Please, fill the form or contact the Innovation
            team to request your access.
          </h3>
          <form ref={form} onSubmit={sendEmail} className="form-container">
            <div className="form-group">
              <label htmlFor="name">Full name:</label>
              <input type="text" id="name" name="from_name" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email address:</label>
              <input type="email" id="email" name="to_name" required />
            </div>

            <div className="form-group">
              <label htmlFor="subject">Department:</label>
              <input type="text" id="subject" name="message" required />
            </div>

            <CustomButton type="submit">Submit</CustomButton>
          </form>
        </div>
      )}
    </Container>
  );
}
