import React, { useState, useEffect } from "react";
import { storage } from "../../lib/init-firebase";
import { ref, getDownloadURL } from "firebase/storage";

export default function ShowImage(props) {
  const [chargerImage, setChargerImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const imageRef = ref(
      storage,
      `${props.chargerFolder}/${props.chargerName}.png`
    );

    getDownloadURL(imageRef)
      .then((url) => {
        setChargerImage([url]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error retrieving image:", error);
        setIsLoading(false);
      });
  }, [props.chargerFolder, props.chargerName]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {chargerImage.map((url, index) => (
        <div key={index}>
          <p style={{ fontWeight: "bold" }}>{props.chargerName}</p>
          <img style={{ width: "180px" }} src={url} alt="charger" />
        </div>
      ))}
    </div>
  );
}
