import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../lib/init-firebase";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { useLocation } from "react-router-dom";
import ShowImage from "../CommonComponents/ShowImage";

const useStyles = makeStyles((theme) => ({
  textLabel: {
    textTransform: "capitalize",
  },
}));

export function CustomAttributeLabel(props) {
  const classes = useStyles();

  return (
    <Typography className={classes.textLabel} variant="subtitle2" gutterBottom>
      {props.children}
    </Typography>
  );
}

export default function ComparisonTableOverview(props) {
  const location = useLocation();
  const selectedChargers = location.state.selectedChargers;
  const chargerArray = Array.from(selectedChargers);

  let newChargerArray = chargerArray.map((str) => {
    let values = str.split(",");
    let firstValue = values[0];
    let secondValue = "";
    if (values.length > 2) {
      secondValue = values.slice(1, 2)[0];
    } else {
      secondValue = values.slice(-1)[0];
    }
    return firstValue + "," + secondValue;
  });
  //console.log(newChargerArray);

  const [chargerData, setChargerData] = useState([]);
  const queries = chargerArray.map((charger) =>
    collection(db, `ChargersList/${charger}/Company overview/`)
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    Promise.all(queries.map((query) => getDocs(query)))
      .then((responses) => {
        const chargerData = responses.flatMap((response) =>
          response.docs.map((doc) => ({
            data: doc.data(),
            id: doc.id,
          }))
        );
        setChargerData(chargerData);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>error...</div>;
  }

  const chargerDataArray = chargerData.map((charger) => {
    return charger.data;
  });
  // console.log(chargerDataArray);

  const chargerCompanies = chargerData.map((charger) => {
    return charger.data["Company name"];
  });

  // console.log(chargerCompanies);

  return (
    <TableContainer style={{ width: "85%", marginBottom: "50px" }}>
      {/* {loading && "Loading..."} */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {chargerDataArray &&
              chargerDataArray.map((item, index) => (
                <TableCell key={index} align="center">
                  {
                    <ShowImage
                      chargerFolder={chargerCompanies[index]}
                      chargerName={newChargerArray[index]}
                    />
                  }
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {chargerDataArray &&
            props.filters.map((filter) => (
              <TableRow hover key={filter}>
                <TableCell
                  style={{ fontWeight: "bold" }}
                  component="th"
                  scope="row"
                >
                  {filter}
                </TableCell>
                {chargerDataArray.map((row, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    {row[filter].toString().toLowerCase() === "false" ||
                    row[filter].toString().toLowerCase() === "no" ? (
                      <CloseIcon style={{ color: "darkred" }} />
                    ) : row[filter].toString().toLowerCase() === "true" ||
                      row[filter].toString().toLowerCase() === "yes" ? (
                      <DoneIcon style={{ color: "green" }} />
                    ) : (
                      row[filter]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
