import React from "react";
import ResponsiveAppBar from "../components/CommonComponents/ResponsiveAppBar";
import PorfolioNavBar from "../components/PortfolioComponents/PortfolioNavBar";

export default function PortfolioAnalysis() {

  return (
    <div>
      <ResponsiveAppBar />
      <PorfolioNavBar />
    </div>
  );
}
